@import "./src/styles/helpers/_index.scss";
.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  overflow: hidden;

  height: 60px;

  border-bottom: 1px solid #e7eaec;
  background-color: #f3f3f4;
}

.Profile {
  display: inline;

  margin-right: 10px;
}

.leftPart {
  display: flex;
  align-items: center;

  margin-left: 20px;
}

.switchButton {
  & > i {
    margin: 0 !important;
  }
}
