@import "./src/styles/helpers/_index.scss";
.range {
  height: 4px;
  width: 100%;
  border-radius: 4px;
  background-color: #849cf5;
  margin-top: 8px;
}


.range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #3863d9;
}
