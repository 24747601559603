@import "./src/styles/helpers/_index.scss";
.TableList {
  &Sort {
    cursor: pointer;
  }
}

.Icon {
  &Ascent {
    display: inline-block;
    position: relative;
    top: 3px;
    padding-left: 5px;

    &:before {
      content: "\F0DE";
    }
  }

  &Descent {
    display: inline-block;
    position: relative;
    top: -3px;
    padding-left: 5px;

    &:before {
      content: "\F0DD";
    }
  }
}