//@import "~bootstrap/dist/css/bootstrap.css";

.label {
  &._btn {
    background: transparent;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
  }
}

._text-align--center {
  text-align: center;
}

.btn-group {
  > .btn {
    &.active {
      background: $color-active;
      box-shadow: none;
    }

    &:active {
      background: rgba(26, 179, 148, 0.1);
      box-shadow: none;
    }
  }
}

@media (min-width: 768px) {
  .modal-450 {
    width: 450px;
  }
}

.fade.show {
  opacity: 1;
}
