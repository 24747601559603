@import "./src/styles/helpers/_index.scss";
.AnnualPlans :global {
  .table > thead:first-child > tr:first-child > th.controls {
    width: 130px;
  }

  .table > thead:first-child > tr:first-child > th.json-download {
    width: 15px;
  }

  .table .name-col {
    word-break: break-all;
  }

  .highlighted-line {
    background-color: #fcf8e3;
  }
}
