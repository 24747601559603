@import "./src/styles/helpers/_index.scss";
.channel-group-editor {
  margin-top: 10px;
  margin-bottom: 50px;
}

.table {
  margin-top: 10px;
  width: 100%;
  max-width: 100%;

  & tbody tr {
    border-top: 1px solid #ddd;
  }

  & td, & th {
    line-height: 1.42857;
    padding: 8px;
    vertical-align: middle;
    text-align: center;
  }

  & td:first-child input {
    border: 1px solid #000;
    color: #000;
  }
}

.add-group {
  display: flex;
  align-items: center;
}

.add-group-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 3px;
  font-size: 20px;
  padding: 0;
  background-color: #1c84c6;
  color: #FFFFFF;
}

.add-group-label {
  font-size: 16px;
}

.min-max-column {
  background-color: #d9edf7;
}

.channels-column {
  width: 50%;
}

.error {
  background-color: $invalid !important;
}
