@import "./src/styles/helpers/_index.scss";
.annual-plan-type-row {
  margin-bottom: 15px;
}

.form-value {
  padding-top: 7px;
}

.table {
  &.centered-cells {
    td,
    th {
      vertical-align: middle;
      text-align: center;
    }
  }

  &.table-selectable {
    tbody tr {
      cursor: pointer;
    }
  }
}

.campaigns-summary {
  margin-top: 40px;
}

.annual-summary {
  .row {
    padding: 12px;
  }
}

.bottom-page-row {
  padding-bottom: 10px;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-right-padding {
  padding-right: 0 !important;
}

.padding-top-bottom-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.padding-top-bottom-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-top-bottom-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}

.margin-left-5px {
  margin-left: 5px;
}

.form-control[date-range-picker][readonly] {
  background-color: inherit;
}

.active-site-ok-button {
  color: #23c6c8;
  cursor: pointer;
}

.active-site-cancel-button {
  color: #ed5565;
  cursor: pointer;
}

.disabled-site-button {
  color: inherit;
  cursor: default !important;
}

.ibox-title.with-buttons {
  h5 {
    line-height: 36px;
    vertical-align: middle;
  }
}

.display-flex {
  display: flex;
}

.flex-end {
  align-items: flex-end;
}

.right-flex-table {
  overflow-x: auto;
  flex-grow: 1;
}

.table-right-solid-border {
  border-right: 1px solid #e7eaec;
}

.no-border-bottom {
  border-bottom: none !important;
}

.border-top-bg-color {
  border-top: 1px solid #ffffff !important;
}

.fixed-column-header-rowspan2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 100px;
}

.table-row-hover {
  background-color: #f5f5f5;
}

.table-selected-row {
  background-color: #fcf8e3;
}

.table-selected-row-hover {
  background-color: #faf2cc;
}

.form-control[date-range-picker][readonly] {
  background-color: #ffffff;
}

.hover-green:hover {
  color: #23c6c8;
}

.hover-red:hover {
  color: #ec4758;
}

.hover-blue:hover {
  color: #1c84c6;
}

.hover-yellow:hover {
  color: #f8ac59;
}

.text-right-important {
  text-align: right !important;
}

.margin-bottom-9px {
  margin-bottom: 9px;
}

.active-ok-button {
  color: #23c6c8;
  cursor: pointer;
}

.active-cancel-button {
  color: #ed5565;
  cursor: pointer;
}

.disabled-button {
  color: inherit;
  cursor: default;
}

.display-inline-block {
  display: inline-block;
}

.margin-top-20px {
  margin-top: 20px;
}

.position-relative {
  position: relative;
}

.campaigns-table-cell-center {
  vertical-align: middle !important;
}

.campaigns-table {
  th,
  td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.custom-column {
  padding: 5px;
  border: none;
  background: transparent;
  text-align: center;
}

.custom-column:focus {
  border: 1px solid grey;
  border-radius: 4px;
  background: white;
}

.ta-holder {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.remove-additional-ta {
  margin-left: 5px;
}

.ta-with-volume {
  display: flex !important;
  flex-direction: row;
  align-items: center;

  &__main {
    font-weight: bold;
  }
}

.table .fixed-campaigns-errors-row td,
.table .scroll-campaigns-errors-row td {
  padding-top: 0;
  padding-bottom: 0;

  border-top: none;
}

.campaign-details-table-row-height {
  height: 51px;
}

.campaign-details-table-cell-center {
  vertical-align: middle !important;
}

table.campaign-details-table-fixed {
  th.select-col {
    min-width: 30px;
  }

  th.channel-col {
    min-width: 210px;
  }

  tr:nth-child(2) {
    height: 36px;
  }
}

table.campaign-details-table-scrolled {
  & td.xs-col,
  th.xs-col {
    min-width: 20px;
  }

  & td.sm-col,
  th.sm-col {
    min-width: 65px;
  }

  & td.narrow-col,
  th.narrow-col {
    min-width: 80px;
  }

  & td.mid-col,
  th.mid-col {
    min-width: 100px;
  }

  & td.wide-col,
  th.wide-col {
    min-width: 120px;
  }
}

table.deal-restrictions-table {
  border: 1px solid #e7eaec;

  & tbody tr {
    height: 49px;
  }

  & td.control-col,
  th.control-col {
    width: 65px;
  }

  & td.mid-col,
  th.mid-col {
    width: 10%;
  }

  & th.month-col {
    width: 6.7%;
  }

  .error-row td {
    padding-top: 0;
  }
}

.tv-campaign-restrictions-table {
  .error-row td {
    padding-top: 0;
  }
}

.deal-restrictions-under-table-controls {
  display: inline-block;
  float: right;
}

.deal-restrictions-under-table-controls-select {
  float: right;

  width: 200px;

  :global {
    .react-select__control {
      min-height: 32px;
    }
    .react-select__indicator {
      padding: 5px 8px;
    }
  }
}

.deal-restrictions-table-tabs {
  border-bottom: none;
  display: inline-block;
  float: left;
  width: 340px;
}

.tabs-container.no-border-bottom .nav-tabs {
  border-bottom-color: transparent;
}

.annualPlan-sites-table {
  tbody > tr > td {
    vertical-align: middle;
    height: 47px;
  }

  .site {
    width: 350px;
  }

  .mid-col {
    min-width: 140px;
    white-space: nowrap;
  }

  .control-col {
    width: 100px;
  }

  td i {
    padding-left: 5px;
  }
}

.campaign-edit-area-header-period {
  padding-top: 10px !important;
}

.uploadButton {
  margin-top: 3px;
  margin-bottom: 3px;
}

.small-single-selectize {
  width: 100%;

  :global {
    .react-select__control {
      min-height: 30px;

      border-radius: 3px;
    }

    .react-select__indicator {
      padding: 4px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

body.app-ad .selectize-control .selectize-input > div {
  border-color: transparent !important;
  padding-left: 0;
}

body > .selectize-dropdown.form-control {
  .selectize-dropdown-content {
    font-size: 12px;
  }

  [data-selectable] {
    white-space: nowrap;
  }
}

.selectize-control.single > .selectize-input > input {
  left: 0 !important;
}

.selectize-dropdown [data-selectable],
.selectize-dropdown .optgroup-header {
  text-align: left;
  white-space: nowrap;
}

.annualPlan-campaigns-fixed-table {
  border-right: 1px solid #e7eaec;

  th,
  td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  thead {
    tr:first-child th {
      border-bottom-color: transparent;

      > div {
        position: relative;

        > div {
          position: absolute;
          left: 50%;

          > div {
            position: relative;
            left: -50%;
          }
        }
      }
    }

    tr:last-child th {
      border-top-color: transparent;
    }
  }
}

.light-green-background {
  background-color: #dff0d8;
}

.budget-group {
  &.campaign {
    min-width: 150px;

    input {
      display: inline-block;
      width: 120px;
    }
  }

  &.internet {
    min-width: 100px;

    input {
      display: inline-block;
      width: 45px;
    }
  }

  &.tv {
    min-width: 100px;

    input {
      display: inline-block;
      width: 45px;
    }
  }
}

.campaign-goals-select-campaign {
  & > div {
    display: inline-block;
    width: 250px;
    margin-left: 10px;
  }

  margin-bottom: 30px;
}

.average-film-duration {
  input {
    display: inline-block;

    width: 80px;

    margin-right: 15px;
  }
}

.iterations {
  input {
    display: inline-block;

    width: 70px;

    margin-right: 15px;
  }
}

.target-reach-selector-select {
  width: 80px;
}

.trade-white-list-config {
  width: 300px;
}

.target-reach-range-select {
  display: flex;
}

.form-control.error,
.form-control:focus.error {
  border: 1px solid #cc5965 !important;
}

.error .selectize-control .selectize-input.not-full,
.error + .selectize-control .selectize-input.not-full,
.error + .selectize-control .selectize-input.invalid {
  border-color: #cc5965;
}

.nav-tabs > a {
  color: #555555;
}

.text-muted {
  color: #aaaaaa;
}

tr.campaign-detail-restrictions-table {
  height: 49px;
}

.tv-cpp-table {
  .data-header {
    min-width: 70px;
  }

  .data {
    padding: 8px 4px;

    font-size: 12px;
    white-space: nowrap;
  }
}

.duration-label {
  text-align: left !important;
}

.iterations-label {
  text-align: left !important;
}

.target-reach-label {
  text-align: left !important;
  padding-top: 24px !important;
}

.target-reach-share {
  align-self: flex-end;
  width: 80px;
  padding: 0px 8px;
  margin-right: 15px;
  height: 38px;
}

.label {
  padding-left: 10px;
}

.goal-switcher {
  div {
    display: flex;

    label {
      padding-left: 10px;
    }
  }
}

.volume-indicator-row {
  display: flex;
  padding-right: 15px;
  color: red;

  .volume-indicator {
    position: relative;
    min-width: 23px;
    margin: 0 6px 0 auto;
  }
}

.target-audience-selectize-container {
  display: flex;
  align-items: center;

  min-width: 300px;

  .volume-indicator {
    position: relative;
    min-width: 40px;
    margin: 0 6px;
  }
  .volume-indicator-loader {
    margin-top: -4px;
  }

  i {
    margin: 0 5px;
  }
}

.channels-selectize-container {
  display: flex;
  align-items: stretch;

  min-width: 200px;
}

.indicator-show {
  margin-left: 4px;
  margin-bottom: 3px;
}

:global {
  .handsontable {
    .c-calendar-winter {
      background-color: #cee1fc;
    }

    .c-calendar-spring {
      background-color: #fff2cb;
    }

    .c-calendar-summer {
      background-color: #daead3;
    }

    .c-calendar-autumn {
      background-color: #d9d2eb;
    }

    .c-calendar-different {
      background-color: #eb9199 !important;
    }
  }
}

.trp-budget-switch {
  float: left;
}

.restriction-base-left-value {
  margin-right: 10px;
}
.restriction-base-value {
  margin-left: 10px;
}
.restriction-value-secondary {
  color: #aaaaaa;
}

:global{
  .sfix-channel {
    position: relative;
    overflow: visible !important;
  }
  .sfix-channel .on-hover {
    z-index: 1000;
    display: none;
    position: absolute;
    top: 0;
    left: 80%;
    white-space: nowrap;
    padding: 8px 10px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    background-color: #373737;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
    min-height: 34px;
  }
  .sfix-channel:hover{
    & .on-hover {
      display: block;
    }
  }
}



.goal-input.goal-input {
  width: 100px !important;
  margin-left: 40px;

  &:invalid {
    border: 1px solid red !important;
  }
}


.one-row-form-control{
  display: flex !important;
  flex-direction: row;
  align-content: center;
  align-items: center;

  & span {
    min-width: 240px;
    max-width: 240px;
  }
}

.label {
  padding-left: 10px;
}

.cancelBtn {
  vertical-align: -5% !important;
}
