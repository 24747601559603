@import "./src/styles/helpers/_index.scss";
.Container {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  min-height: 100%;
  height: 100%;
}

.Main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  position: relative;
}

.Content {
  flex: 1 1 auto;

  position: relative;

  height: 100%;

  &Scrollbar {
    position: relative;
    height: 100%;

    padding: 0 15px;

    &:after {
      content: '';

      display: block;

      height: 50px;
    }
  }
}
