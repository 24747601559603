@import "~react-dates/lib/css/_datepicker.css";

:global {
  .DateRangePicker {
    .DateInput__small {
      width: 87px;
    }
    .DateInput_input__small {
      height: 30px;

      padding: 5px 10px;

      font-size: 12px;
      font-weight: normal;
      line-height: 1.5;

      border-radius: 3px;
    }
  }
  .datepicker-container-error .DateRangePickerInput__withBorder {
    border-color: #cc5965;
  }
}
