.handsontable {
  .ht_master table thead,
  .ht_master table tr th {
    visibility: visible;
  }
  .table.htCore {
    > thead > tr > th,
    > tbody > tr > td {
      padding: 8px;

      font-size: 13px;
      text-align: center;
      vertical-align: middle;

      &.invalid {
        background-color: $invalid !important;
      }

      &.incorrect {
        background-color: $yellow !important;

        &:after {
          content: "\f06a";
          padding-left: 5px;
          font-family: FontAwesome;
          font-size: 1.2em;
          text-rendering: auto;
          color: $invalid;
        }
      }

      &.notification {
        &:after {
          content: "\f06a";
          padding-left: 5px;
          font-family: FontAwesome;
          font-size: 1.2em;
          text-rendering: auto;
          color: $invalid;
        }
      }
    }
    > thead > tr > th {
      font-weight: 700;
      white-space: normal;
    }
  }
  .htDisabled {
    opacity: 0.6;
  }
  .htButton {
    display: block;
    padding: 2px 8px;
    margin: 5px auto 0;
  }
  .colHeader_wrap {
    word-break: break-word;
  }
  &.htColumnHeaders {
    z-index: 0;
  }
}
