@mixin line-height-initial {
  line-height: 1.42857143;
}

@mixin labels-container {
  .label {
    display: inline-block;

    margin: 0 2px;
  }

  margin-left: -2px;
  margin-right: -2px;

}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin orientation($orientation: landscape) {
  @media (orientation: $orientation) {
    @content
  }
}

@mixin breakpoint($width) {
  @media (max-width: $width) {
    @content
  }
}

@mixin breakpoint-min-width($width) {
  @media (min-width: $width) {
    @content
  }
}

@mixin breakpoint-max-height($height) {
  @media (max-height: $height) {
    @content
  }
}

@mixin breakpoint-max-size($width, $height) {
  @media (max-width: $width) and (max-height: $height) {
    @content
  }
}

@mixin breakpoint-min-size($width, $height) {
  @media (min-width: $width) and (min-height: $height) {
    @content
  }
}
