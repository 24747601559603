.c-disablable-block {
  position: relative;

  &::after {
    content: " ";
    display: none;
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;

    z-index: 999;
    background: rgba(255, 255, 255, 0.7);
  }

  &._disabled {
    &._max-height-150 {
      max-height: 150px;
      overflow: hidden;
    }

    &::after {
      display: block;
    }
  }
}