@import "./src/styles/helpers/_index.scss";

.film-durations {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.new-duration {
  display: flex;

  &-share {
    width: 80px;
    padding: 0px 8px;
  }

  &-length {
    width: 150px;
  }
}

.add-duration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 3px;

  font-size: 20px;
  padding: 0;
  background-color: #1c84c6;
  color: #FFFFFF;


  &:hover {
    background-color: #1a7bb9
  }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}

.duration-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.duration-item {
  padding: 5px 15px;
  height: 30px;
  background-color: #dff0d8;
  margin: 10px 10px 10px 0;

  &-cross {
    border: none;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    color: black;
    background-color: transparent;
    border-radius: 3px;

    &:hover {
      background-color: rgba(0,0,0,0.2);
    }
  }
}

.durations-error {
  width: 100%;
}

.error {
  box-shadow: 0px 0px 3px 1px red;
  border-color: transparent;
}
