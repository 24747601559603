.c-calendar-winter {
  background-color: #cee1fc;
}

.c-calendar-spring {
  background-color: #fff2cb;
}

.c-calendar-summer {
  background-color: #daead3;
}

.c-calendar-autumn {
  background-color: #d9d2eb;
}

.c-calendar-different {
  background-color: #eb9199 !important;
}
