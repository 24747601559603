.js-switch {
  display: inline-block;
  pointer-events: none;

  cursor: pointer;

  &--disabled {
    opacity: 0.5;

    cursor: default;
  }
}
