.react-select__control {
  div & {
    transition: border-color 0.15s ease-in-out 0s;
    border-color: #e5e6e7;

    &:hover {
      border-color: #e5e6e7;
      cursor: pointer;
    }
  }

  div &--is-focused {
    box-shadow: none;
    border-color: #1ab394;

    &:hover {
      border-color: #1ab394;
    }
  }
}

.react-select__menu {
  div & {
    z-index: 9999;
  }
  &-list {
    overflow: hidden;
  }
}

.error .react-select__control {
  border-color: #cc5965 !important;
}
