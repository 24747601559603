.c-smart-table {
  th {
    &[st-sort] {
      cursor: pointer;
    }

    /*.fa {
      display: none;
    }*/
  }

  .st-sort-ascent {
    .fa {
      display: inline-block;
      position: relative;
      top: 3px;

      &:before {
        content: "\f0de";
      }
    }
  }
  .st-sort-descent {
    .fa {
      display: inline-block;
      position: relative;
      top: -3px;

      &:before {
        content: "\f0dd";
      }
    }
  }
}
