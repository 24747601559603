[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.d-flex {
  display: flex;
}

.ibox-tools a i {
  color: rgb(103, 106, 108);
}

.wizard-step-title {
  background: lightgrey;
}

._display--block {
  display: block !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

._line-height--100 {
  line-height: 100%;
}

._line-height--button {
  line-height: 34px;
}

._white-space--nowrap {
  white-space: nowrap;
}

._table-layout--fixed {
  table-layout: fixed;
}

._padding-left--0 {
  padding-left: 0;
}

._padding-top--0 {
  padding-top: 0;
}

._margin-top--50 {
  margin-top: 50px;
}

._margin-horizontal--10 {
  margin-left: 10px;
  margin-right: 10px;
}

._cursor--pointer {
  cursor: pointer;
}

._cursor-initial-i {
  cursor: initial !important;
}

button {
  &.unstyled {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: none;
  }
}

.btn {
  i.fa {
    margin-right: 4px;
  }
}

.btn.navbar-minimalize {
  i.fa {
    margin-right: 0;
  }
}

.link-blue {
  cursor: pointer;
  color: #337ab7;
  text-decoration: none;
}

._visibility--hidden {
  visibility: hidden;
}

.agency-name {
  display: inline-block;
  font-size: 34px;
  font-weight: 100;
  line-height: 34px;
  height: 34px;
  text-shadow: 1px 1px 2px #999;
}

.checkbox-inline {
  padding-left: 0;
}

.lr-drop-target-before{
  border-left: 2px solid orange;
}

.lr-drop-target-after{
  border-right: 2px solid orange;
}

.lr-drag-source-after{
  background-color: #DDD;
}

// Fullscreen functions

.fullscreen-ibox-mode .animated {
  animation: none;
}

body.fullscreen-ibox-mode {
  overflow-y: hidden;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.ibox.fullscreen .ibox-content {
  min-height: calc(100% - 48px);
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  .ibox {
    height: 100%;
    padding-bottom: 75px;
    margin-bottom: -75px;
    .ibox-content {
      height: 100%;
    }
  }
  .panel {
    height: 100%;
  }

}

// removes arrows for input type="number"
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

body {
  min-width: 1200px;
  overflow-x: auto;
}

.inline-button-confirm-active, .inline-button-cancel-active, .inline-button-edit-active, .inline-button-delete-active {
  cursor: pointer;
}

.inline-button-confirm-active:hover {
  color: #23c6c8;
}

.inline-button-cancel-active:hover {
  color: #ED5565;
}

.inline-button-delete-active:hover {
  color: #ED5565;
}

.inline-button-edit-active:hover {
  color: #f8ac59;
}

.inline-button-disabled {
  color: inherit;
  cursor: default !important;
}

.disabled {
  color: rgb(200, 200, 200);
}
