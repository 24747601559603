@import "./src/styles/helpers/_index.scss";
.container {
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  height: 50px;
  width: 250px;
}

.select {
  flex-grow: 5;
}
