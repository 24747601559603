.global-spinner {
  display: none;
}

.loading {
  .global-spinner {
    opacity:    0.7;
    background: #000;
    width:      100%;
    height:     100%;
    z-index:    10000;
    top:        0;
    left:       0;
    position:   fixed;
    display: flex;               /* establish flex container */
    align-items: center;         /* vertically center flex items */
  }
}

.loading-indicator {
  margin-right: 8px;
}
