@import "./src/styles/helpers/_index.scss";
.buttons-group {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.button {
  max-width: 180px;
  margin-right: 5px;
  white-space: break-spaces !important;
}

.upload {
  > input[type="file"] {
    display: none;
  }
}
