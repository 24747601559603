@import "./src/styles/helpers/_index.scss";
//fixing fix in _md-skin.fix.scss
.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #ffffff;
}

.table-cell-vertical-align-middle {
  vertical-align: middle !important;
}

.annual-plan-type-row {
  margin-bottom: 15px;
}

.form-value {
  padding-top: 7px !important;
}

.table {
  &.centered-cells {
    td, th {
      vertical-align: middle;
      text-align: center;
    }
  }
  &.weeks-table {
    /*table-layout: fixed;*/
    td {
      &.week-cell {
        width: 14px;
      }
    }
  }
}

.month-budget-column {
  min-width: 80px;
}

.form-group {
  margin-bottom: 5px !important;;
}

.overflow-x-auto {
  overflow-x: auto;
}

.display-flex {
  display: flex;
}

.right-flex-table {
  overflow-x: auto;
  flex-grow: 1;
  table,
  table > thead:first-child > tr > th, table,
  table > tbody > tr > td:first-child {
    border-left: none;
  }
}

.no-border-bottom {
  border-bottom: none !important;
}

.border-top-bg-color {
  border-top: 1px solid #F5F5F6 !important;
}


.trade-white-list-button {
  position: relative;
}

.trade-white-list-option {
  padding: 6px;

  & + & {
    border-top: 1px solid grey;
  }

  cursor: pointer;
  transition: 0.3s ease;
  &:hover {

    background: #f4f6ff;
  }
}

.trade-white-list-dropdown {
  position: absolute;
  width: 100%;
  top: 24px;
  border: 1px solid gray;
  left: 0;
  z-index: 2;
  background: #fff;

}

.trade-white-list {
  & span + span {
    margin-left: 5px;
  }
}

.position-relative {
  position: relative;
}

.fixed-column-header-rowspan2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left:0;
  right:0;
  width: 100px
}

.deal-brief-export-button {
  display: inline-block;
  float: right;
}

.ad-campaigns-calc-res-charts {
  margin-top: 20px;
}

.quality-fixed-table {
  thead tr {
    height: 44px;
  }
  th.channel-col {
    min-width: 200px;
  }
  tr:nth-child(2) {
    th {
      border-top-color: transparent;
    }
  }
}

.quality-scroll-table {
  .percent-col {
    min-width: 100px;
  }
}

.annualPlan-campaigns-show-fixed-table {
  th, td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  border-right: 1px solid #e7eaec;
  thead {
    tr:first-child th {
      border-bottom-color: transparent;
      > div {
        position: relative;
        > div {
          position: absolute;
          left: 50%;
          > div {
            position: relative;
            left: -50%;
          }
        }
      }
    }
    tr:last-child th {
      border-top-color: transparent;
    }
  }
}

.annualPlan-campaigns-show-fixed-table,
.campaigns-show-table {
  th.cell-with-border,
  td.cell-with-border {
    border-left: 1px solid #e7eaec !important;
  }
}

.light-green-background {
  background-color: #dff0d8;
}

.light-blue-background {
  background-color: #b3e5ff;
}

.light-yellow-background {
  background-color: #faf2cc;
}

.campaigns-table-cell-center {
  vertical-align: middle !important;
}

.campaigns-table {
  th, td {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.c-btn-disable {
  &, &:hover {
    &, &:active {
      cursor: not-allowed;
      box-shadow: none;
      opacity: .65;
      background-color: #c8c8c8;
      border-color: #c8c8c8;
    }
  }
}

.table-row-warning {
  > td {
    background-color: $invalid !important;
  }
}

.table-row-error {
  > td {
    color: $red;
    font-weight: 700;
    text-align: left !important;
    padding-left: 50px !important;
  }
}

.table-cell-warning {
  background-color: $yellow !important;

  &:after {
    content: "\f06a";
    padding-left: 5px;
    font-family: FontAwesome;
    text-rendering: auto;
    color: $invalid;
  }
}


.outdated-chart {
  padding: 20px;
  font-size: 18px;

  & button {
    margin-left: 4px;
  }
}



.range-slider-form-group {
  position: relative;
}

.range-slider-second {
  position: absolute;
  top: 23px;
}
