.file-input-container {
  display: flex;

  & > * {
    flex: 0;

    &:first-child {
      flex: 1 1 auto;
    }
  }
  .form-control {
    border-radius: 1px 0 0 1px;
  }
  .btn {
    height: 34px;

    border-left: 0;

    border-radius: 0 1px 1px 0;
  }
}
